module services {
    export module master {
        export class goodsReceiptNoteService implements interfaces.master.IGoodsReceiptNoteService {
            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.master.IGoodsReceiptNotesDisplay> {
                return this.$resource<interfaces.master.IGoodsReceiptNotesDisplay>(this.ENV.DSP_URL + "GoodsReceiptNote/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getGRNDeliveryInstructionViewModelList(): ng.resource.IResourceClass<interfaces.master.IGRNDeliveryInstructionViewModel> {
                return this.$resource<interfaces.master.IGRNDeliveryInstructionViewModel>(this.ENV.DSP_URL + "GoodsReceiptNote/GetDeliveryInstructionsViewModelList", {
                    grnId: '@grnId',
                    entityId: '@grnId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getDISKUAllocationViewModelList(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionSKUAllocation> {
                return this.$resource<interfaces.purchase.IDeliveryInstructionSKUAllocation>(this.ENV.DSP_URL + "GoodsReceiptNote/GetDISKUAllocationViewModel", {
                    grnId: '@grnId'
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getGoodsReceiptNoteDetail(): ng.resource.IResourceClass<interfaces.master.IGoodsReceiptNote> {
                return this.$resource<interfaces.master.IGoodsReceiptNote>(this.ENV.DSP_URL + "GoodsReceiptNote/GetGoodsReceiptNoteDetail",
                    {
                        grnId: "@grnId",
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

        
            saveGoodsReceiptNote(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsReceiptNote/SaveGRN", {}, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });

            }   

        }
    }
    angular.module("app").service("goodsReceiptNoteService", services.master.goodsReceiptNoteService);
}